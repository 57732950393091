import { Component, Input, ViewChild } from '@angular/core';
import { ModalComponent } from '../../../../standalone/components/modal/modal.component';

@Component({
	selector: 'rq-legal-modal',
	templateUrl: './legal-modal.component.html',
	styleUrls: ['./legal-modal.component.scss']
})
export class LegalModalComponent {
	@ViewChild(ModalComponent, { static: false })
	public modal!: ModalComponent;

	@Input()
	public year!: number;

	@Input()
	public version!: string;

	@Input()
	public isBeta!: boolean;

	public closeModal() {
		this.modal.hide();
	}

	public showModal() {
		this.modal.show();
	}
}
