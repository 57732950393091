<section *ngFor="let group of data.groups; let groupIndex = index"
         [class.comparison-collapsible-visible]="group.isVisible"
         class="comparison-collapsible-group">
  <ng-container *ngIf="!isRowLoading(group, groupIndex)">
    <h4 (click)="toggleCollapse(groupIndex)"
        (mouseenter)="triggerMouseEnter(undefined, undefined, groupIndex)"
        *ngIf="group.title"
        [class.comparison-collapsible-title-hover]="isHoverActive(undefined, undefined, groupIndex)"
        [class.comparison-row-highlighted]="group.title.isHighlighted"
        class="m-b-none comparison-collapsible-title">
      <strong [attr.title]="group.title.label"
              class="comparison-collapsible-title-label comparison-collapsible-section">
        {{group.title.label}}
      </strong>
      <strong [attr.title]="group.title.value"
              class="comparison-collapsible-title-value comparison-collapsible-section text-right">
        <ng-container *ngTemplateOutlet="valueTemplate; context: {entity: group.title }"></ng-container>
      </strong>
    </h4>
    <ul *ngIf="isGroupVisible(group)"
        class="list-unstyled">
      <li (mouseenter)="triggerMouseEnter(row, rowIndex, groupIndex)"
          *ngFor="let row of group.rows; let rowIndex = index"
          [class.comparison-row-highlighted]="row.isHighlighted"
          [class.comparison-row-hover]="isHoverActive(row, rowIndex, groupIndex)"
          class="comparison-collapsible-row">
        <div class="comparison-collapsible-row-inner">
          <ng-container *ngIf="!isCustomTemplate(row)">
            <span [attr.title]="getLabel(row)"
                  class="comparison-collapsible-section">
              {{getLabel(row)}}
            </span>
            <span class="comparison-collapsible-section text-right">
              <ng-container *ngTemplateOutlet="valueTemplate; context: {entity: row }"></ng-container>
            </span>
          </ng-container>
          <ng-container *ngIf="isCustomTemplate(row)">
            <div class="comparison-value-container">
              <ng-container *ngTemplateOutlet="getTemplate(row); context: {entity: row }"></ng-container>
            </div>
          </ng-container>
        </div>
      </li>
    </ul>
  </ng-container>
  <ng-container *ngIf="isRowLoading(group, groupIndex)">
    <div class="p-md b b-top">
      <div class="loading-bar"></div>
    </div>
  </ng-container>
</section>
