<div class="bar-container">
  <div *ngFor="let item of listToDisplay"
       class="bar-segment"
       [ngStyle]="{
        'width': item.percentage + '%',
        'background-color': item.color
      }"
       [title]="item.name"></div>
</div>

<div class="legend">
  <span class="legend-item total-count">{{ totalCount }} Total</span>
  <span class="legend-item"
        *ngFor="let item of listToDisplay">
    <span class="bullet"
          [ngStyle]="{ 'background-color': item.color }"></span>
    <span>{{ item.name }}</span>
  </span>
</div>
