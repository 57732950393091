<header class="header">
  <section class="header__content_left">
    <img class="header__brand"
         src="../../../../assets/images/brand_logo_color.svg"
         alt="RQ-Logo" />
    <span class="m-l-sm"
          *ngIf="licenseType"
          [ngClass]="licenseType.badgeClass">{{ licenseType.label }}</span>
  </section>

  <section class="header__content_right">
    <rq-legal-entities-dropdown *ngIf="isPortfolionDropdownVisible"
                                [availableLegalEntities]="portfolioLegalEntities"
                                [config]="portfolioConfig">
    </rq-legal-entities-dropdown>

    <section class="header__actions">
      <ng-container *rqHasLicense="[LicensePermission.FULL]">
        <button *ngIf="isHowToVisible"
                class="btn-icon"
                (click)="navigateToHowToPage()">
          <rq-icon title="{{ 'How To?' | translate }}"
                   href="icon-help-circle"></rq-icon>
        </button>
      </ng-container>

      <button #notificationButton
              *ngIf="isNotificationVisible"
              (click)="toggleNotificationDropdown()"
              e2e-test="notification-menu-item"
              [class.active]="expandNotification"
              class="header__notifications btn-icon">
        <rq-icon title="{{ 'global_notifications_label' | translate }}"
                 href="icon-notification"></rq-icon>
        <div *ngIf="newNotificationsCount"
             class="header__notifications_circle"></div>
      </button>

      <button #analysisButton
              class="btn-icon"
              (click)="toggleAnalysisSummary()"
              *ngIf="isAnalysisSummaryVisible"
              (rqClickOutside)="closeAnalysisSummary()"
              [class.active]="expandAnalysisSummary">
        <rq-icon title="{{ 'scenario_comparisonSummary_title' | translate }}"
                 href="icon-scroll"></rq-icon>
        <rq-analysis-summary [@slideInFromRightAnimation]
                             *ngIf="expandAnalysisSummary"
                             (showComparisonModal)="showComparisonModal()"
                             (closeAnalysisSummary)="closeAnalysisSummary()"></rq-analysis-summary>
      </button>
    </section>

    <section #userMenuButton
             class="header__user"
             (click)="toggleUserMenu()">
      <button class="header__avatar"
              [class.active]="expandUserMenu">{{ userInitials }}</button>
    </section>
  </section>
</header>

<rq-notification-popup [@popupAnimation]
                       *ngIf="expandNotification"
                       (rqClickOutside)="closeNotification()"
                       [excludeElement]="notificationButtonRef?.nativeElement"
                       [newNotificationsCount]="newNotificationsCount"
                       (navigateToViewAll)="navigateToNotification()"
                       (navigateToNotification)="navigateToNotification($event)">
</rq-notification-popup>

<rq-user-popup [@popupAnimation]
               *ngIf="expandUserMenu"
               [excludeElement]="userMenuButtonRef?.nativeElement"
               (rqClickOutside)="closeUserMenu()"
               [user]="user"
               [userInitials]="userInitials"
               (closeModal)="closeUserMenu()"
               (logoutAction)="showLogoutConfirmation()"></rq-user-popup>

<rq-analysis-summary [@slideInFromRightAnimation]
                     *ngIf="expandAnalysisSummary"
                     (rqClickOutside)="closeAnalysisSummary()"
                     [excludeElement]="analysisButtonRef?.nativeElement"
                     (showComparisonModal)="showComparisonModal()"
                     (closeAnalysisSummary)="closeAnalysisSummary()"></rq-analysis-summary>

<rq-logout-confirmation-modal (accept)="confirmLogout()"></rq-logout-confirmation-modal>

<rq-comparison-modal></rq-comparison-modal>
