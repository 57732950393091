<ng-container *ngIf="!isSidebar">
  <section class="footer-container">
    <footer class="boxed-wrapper footer-content">
      <a class="footer-company"
         target="_blank"
         rel="noopener"
         href="https://threatconnect.com">
        <span>ThreatConnect</span>
      </a>
      <span>&nbsp;</span>
      <span> {{ 'footer_label_info' | translate : { YEAR: year, VERSION: version } }} </span>
      <span *ngIf="isBeta"
            class="badge-secondary-sm-b m-l-xs">{{ 'global_beta_label' | translate }}</span>
    </footer>
  </section>
</ng-container>

<ng-container *ngIf="isSidebar">
  <section class="navigation-sidebar__footer pointer"
           (click)="modal.showModal()">
    <div class="navigation-sidebar__item"
         [class.collapsed]="!isSidebarExpanded">
      <div class="navigation-sidebar__about">
        <rq-icon href="icon-info-circle"
                 color="color-secondary"></rq-icon>
        <span *ngIf="isSidebarExpanded">About</span>
      </div>

      <div class="navigation-sidebar__version"
           *ngIf="isSidebarExpanded">
        <span class="navigation-sidebar__version_text">v.{{ version }}</span>
        <span *ngIf="isBeta"
              class="badge-secondary-sm-b">{{ 'global_beta_label' | translate }}</span>
      </div>
    </div>
  </section>
</ng-container>

<rq-legal-modal #modal
                [isBeta]="isBeta"
                [year]="year"
                [version]="version"></rq-legal-modal>
