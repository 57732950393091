<rq-modal class="modal-xl">
  <rq-modal-header class="modal-header">
    <span>{{'global_comparison_analysis_options_label' | translate}}</span>
    <rq-icon href="icon-close"
             (click)="closeModal()"></rq-icon>
  </rq-modal-header>

  <rq-modal-body>
    <div class="capability-body">
      <ng-container *ngFor="let capability of content; let first = first">
        <div class="capability-container"
             [ngClass]="{'gradient-background': first}">
          <p class="capability-title"
             [ngClass]="{'accent': first}">{{capability.label}}</p>
          <p class="capability-description"
             [ngClass]="{'primary': first}">{{capability.description}}</p>
          <div class="capabilities-content-container">
            <p class="capabilities-subtitle">{{'global_capabilities_label' | translate}}</p>
            <hr class="horizontal-line">
            <div class="capabilities-container"
                 *ngFor="let item of capability.capabilities">
              <rq-icon href="icon-check"></rq-icon>
              <span class="capability-text">{{item.label}}</span>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </rq-modal-body>

  <rq-modal-footer class='d-flex justify-content-between align-items-center'>
    <button type='button'
            e2e-test='close-modal-button'
            (click)='closeModal()'
            class='btn-secondary'>{{'global_label_close' |
      translate}}</button>
  </rq-modal-footer>
</rq-modal>
