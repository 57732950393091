<header class="analysis-summary__header">
  <button class="btn-icon-sm"
          (click)="hideAnalysisSummary()">
    <rq-icon href="icon-arrow-enter"></rq-icon>
  </button>
  <button (click)="openComparisonModal()"
          class="btn-secondary-sm">
    <rq-icon href="icon-help-circle"
             color="color-secondary"></rq-icon>
    <span>Analysis Guide</span>
  </button>
</header>

<section class="analysis-summary__container">
  <ng-container *ngIf="currentAnalysisId; else noDataToDisplay">
    <rq-loading-box [type]="loadingType.Card"
                    [promise]="loadingPromise">
      <article class="analysis-summary__details">
        <p class="analysis-summary__title">{{ 'scenario_comparisonSummary_title' | translate }}</p>
        <div class="analysis-summary__scenario">
          <p class="analysis-summary__type">Analysis Type</p>
          <p class="analysis-summary__description">{{ analysisType | analysisSummaryTypeLabel }}</p>
        </div>
      </article>

      <ng-container *ngFor="let summary of analysisSummary; let i = index">
        <article class="analysis-summary__items">
          <p class="analysis-summary__category">
            {{ 'step_number_label' | translate : { stepNumber: i + 1 } }}
            <span>{{ summary.category | translate }}</span>
          </p>
          <div *ngFor="let item of summary.items"
               class="analysis-summary__item">
            <rq-icon color="color-secondary"
                     *ngIf="isStepComplete(item)"
                     href="icon-check-circle"></rq-icon>
            <rq-icon color="color-secondary"
                     *ngIf="!isStepComplete(item)"
                     href="{{ isStepActive(item) ? 'icon-loading-spinner' : 'icon-circle-dashed-large' }}"
                     [ngClass]="isStepActive(item) ? 'analysis-summary__spinner' : ''"></rq-icon>
            <p class="analysis-summary__description">
              {{ item.description | translate }}
            </p>
          </div>
        </article>
      </ng-container>
    </rq-loading-box>
  </ng-container>
</section>

<ng-template #noDataToDisplay>
  <section class="no-data-container">
    <img src="{{ '../../../../../../../../assets/images/no-analysis-summary-placeholder.svg' }}"
         alt="{{ 'global_no_analysis_summary_label' | translate }}" />
    <span class="no-data-title">{{ 'global_no_analysis_summary_label' | translate }}</span>
    <span class="no-data-description">{{ 'global_no_analysis_summary_description' | translate }}</span>
  </section>
</ng-template>
