import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { BaseComponent } from '../../../shared/components/base.component';
import { LogoutConfirmationModalComponent } from './logout-confirmation-modal/logout-confirmation-modal.component';
import { NotificationListViewModel } from '../../../shared/models/notification-list.viewmodel';
import { NotificationService } from '../../../shared/services/notification.service';
import { AnalysisPortfolioLegalEntityModel } from '../../../server/models/analysis-portfolio-legal-entity-model.model';
import { LogoType } from '../../../server/enums/logo-type.enum';
import { ApplicationInstanceStore } from 'src/app/shared/core/application.service';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { IdentityService } from '../../services/identity.service';
import { UserViewModel } from '../../models/user-edit-form.model';
import { ComparisonModalComponent } from './analysis-summary/comparison-modal/comparison-modal.component';
import { popupAnimation } from 'src/app/standalone/animations/popup-animation';
import { slideInFromRightAnimation } from 'src/app/standalone/animations/slide-in-animation';

@Component({
	selector: 'rq-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
	animations: [popupAnimation, slideInFromRightAnimation]
})
export class HeaderComponent extends BaseComponent implements OnInit, OnDestroy {
	@ViewChild(LogoutConfirmationModalComponent, { static: true })
	public logoutModal!: LogoutConfirmationModalComponent;

	@ViewChild(ComparisonModalComponent, { static: true })
	public detailsModal!: ComparisonModalComponent;

	@ViewChild('analysisButton')
	public analysisButtonRef?: ElementRef;

	@ViewChild('userMenuButton')
	public userMenuButtonRef?: ElementRef;

	@ViewChild('notificationButton')
	public notificationButtonRef?: ElementRef;

	@Input()
	public logoType!: LogoType;

	@Input()
	public isNotificationVisible = true;

	@Input()
	public isPortfolionDropdownVisible = true;

	@Input()
	public isHowToVisible = false;

	@Input()
	public isAnalysisSummaryVisible = false;

	@Input()
	public portfolioLegalEntities!: Array<AnalysisPortfolioLegalEntityModel>;

	@Input()
	public portfolioConfig!: { portfolioName: string; hasEntepriseAccess: boolean; isAtLeaseOneRiskAnalysisConfigured: boolean };

	@Output()
	public readonly logoutAction = new EventEmitter();

	@Output()
	public readonly navigateHomeAction = new EventEmitter();

	@Output()
	public readonly navigateHowToAction = new EventEmitter();

	@Output()
	public readonly navigateToNotificationAction = new EventEmitter<NotificationListViewModel | undefined>();

	public licenseType!: { label: string; badgeClass: string };

	public isRouteChanging = false;

	public expandNotification!: boolean;

	public expandUserMenu!: boolean;

	public expandAnalysisSummary!: boolean;

	public newNotificationsCount = 0;

	public userInitials!: string;

	public user!: UserViewModel;

	constructor(
		private router: Router,
		private notificationService: NotificationService,
		private applicationService: ApplicationInstanceStore,
		private translationService: TranslationService,
		private identityService: IdentityService
	) {
		super();
	}

	public async ngOnInit() {
		this.user = await this.identityService.getCurrentUser();
		this.userInitials = this.getUserInitials(this.user.name);

		this.subscribeToEvents();

		if (this.isNotificationVisible) {
			this.subscribeToNotificationEvents();
			this.notificationService.initNotifications();
		}

		this.getLicenseType();
	}

	public ngOnDestroy(): void {
		super.ngOnDestroy();
		this.notificationService.destroyNotifications();
	}

	public navigateHome() {
		this.navigateHomeAction.emit();
	}

	public showComparisonModal() {
		this.closeAnalysisSummary();
		this.detailsModal.showModal();
	}

	public async showLogoutConfirmation() {
		this.closeUserMenu();
		await this.logoutModal.show();
	}

	public confirmLogout() {
		this.logoutAction.emit();
	}

	public closeNotification() {
		this.expandNotification = false;
	}

	public navigateToNotification(item?: NotificationListViewModel) {
		this.navigateToNotificationAction.emit(item);
		this.closeNotification();
	}

	public navigateToHowToPage() {
		this.navigateHowToAction.emit();
	}

	public toggleNotificationDropdown() {
		if (this.expandNotification) {
			this.closeNotification();
		} else {
			this.expandNotification = true;
		}
	}

	public toggleUserMenu() {
		if (this.expandUserMenu) {
			this.closeUserMenu();
		} else {
			this.expandUserMenu = true;
		}
	}

	public toggleAnalysisSummary() {
		if (this.expandAnalysisSummary) {
			this.closeAnalysisSummary();
		} else {
			this.expandAnalysisSummary = true;
		}
	}

	public closeAnalysisSummary() {
		this.expandAnalysisSummary = false;
	}

	public closeUserMenu() {
		this.expandUserMenu = false;
	}

	private subscribeToEvents() {
		this.subscriptions.push(
			this.router.events.subscribe(event => {
				if (event instanceof ActivationStart) {
					this.isRouteChanging = true;
					setTimeout(() => (this.isRouteChanging = false), 1000);
				}
			})
		);
	}

	private subscribeToNotificationEvents() {
		this.subscriptions.push(
			this.notificationService.newNotificationCount$.subscribe(value => {
				this.newNotificationsCount = value;
			})
		);

		this.subscriptions.push(
			this.notificationService.increaseNotificationCount$.subscribe(value => {
				this.newNotificationsCount += value;
			})
		);
	}

	private getLicenseType() {
		if (this.applicationService.isPro) {
			this.licenseType = { label: this.translationService.get('global_pro_label'), badgeClass: 'badge-primary-sm' };
		} else if (this.applicationService.isEnterprise) {
			this.licenseType = { label: this.translationService.get('global_enterprise_label').slice(0, 3), badgeClass: 'badge-royal-sm' };
		}
	}

	private getUserInitials(name: string) {
		const words = name.trim().split(' ');
		if (words.length === 1) {
			return words[0].slice(0, 2).toUpperCase();
		} else {
			return words[0][0].toUpperCase() + words[1][0].toUpperCase();
		}
	}
}
