import { ChangeDetectionStrategy, Component, Input, SimpleChanges } from '@angular/core';
import { SplitBarDataModel } from './split-bar-data.model';

@Component({
	selector: 'rq-split-bar',
	templateUrl: './split-bar.component.html',
	styleUrls: ['./split-bar.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplitBarComponent {
	@Input()
	public data!: Array<SplitBarDataModel>;

	@Input()
	public totalCount!: number;

	public listToDisplay!: Array<SplitBarDataModel>;

	public ngOnChanges(changes: SimpleChanges) {
		const data = changes.data.currentValue as Array<SplitBarDataModel>;
		if (data) {
			const total = data.reduce((sum, item) => sum + item.value, 0);

			this.listToDisplay = this.data.map(item => ({
				...item,
				percentage: (item.value / total) * 100
			}));
		}
	}
}
