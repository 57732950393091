import { Component, ViewChild } from '@angular/core';
import { TranslationService } from 'src/app/shared/services/translation.service';
import { ModalComponent } from '../../../../../standalone/components/modal/modal.component';

@Component({
	selector: 'rq-comparison-modal',
	templateUrl: './comparison-modal.component.html',
	styleUrls: ['./comparison-modal.component.scss']
})
export class ComparisonModalComponent {
	@ViewChild(ModalComponent, { static: false })
	public modal!: ModalComponent;

	public content = [
		{
			label: this.translationService.get('global_core_rq_label'),
			description: this.translationService.get('global_core_rq_description'),
			capabilities: [
				{ label: this.translationService.get('global_ai_ml_capability_description') },
				{ label: this.translationService.get('global_attack_surface_capability_description') },
				{ label: this.translationService.get('global_mitre_ttp_capability_description') },
				{ label: this.translationService.get('global_controls_capability_description') },
				{ label: this.translationService.get('global_cve_capability_description') },
				{ label: this.translationService.get('global_recommendations_capability_description') },
				{ label: this.translationService.get('global_tp_capability_description') }
			]
		},
		{
			label: this.translationService.get('global_sa_fair_label'),
			description: this.translationService.get('global_sa_fair_description'),
			capabilities: [
				{ label: this.translationService.get('global_leverage_ai_ml_capability_description') },
				{ label: this.translationService.get('global_pre_modeled_capability_description') },
				{ label: this.translationService.get('global_generates_recommendations_capability_description') },
				{ label: this.translationService.get('global_tp_capability_description') }
			]
		},
		{
			label: this.translationService.get('global_manual_fair_label'),
			description: this.translationService.get('global_manual_fair_description'),
			capabilities: [
				{ label: this.translationService.get('global_adherence_fair_capability_description') },
				{ label: this.translationService.get('global_monte_carlo_capability_description') }
			]
		}
	];

	constructor(private translationService: TranslationService) {}

	public closeModal() {
		this.modal.hide();
	}

	public showModal() {
		this.modal.show();
	}
}
