import { AfterViewInit, Component, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NavigationListDataViewModel } from '../../../shared/models/navigation-list-data.viewmodel';
import { NavigationListStatesViewModel } from '../../../shared/models/navigation-list-states.viewmodel';
import { NavigationListViewModel } from '../../../shared/models/navigation-list.viewmodel';
import { BaseComponent } from '../../../shared/components/base.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SidebarStateStore } from '../../../routes/control-analysis/_common/store/sidebar-state.store';

@Component({
	selector: 'rq-navigation-sidebar',
	templateUrl: './navigation-sidebar.component.html',
	styleUrls: ['./navigation-sidebar.component.scss'],
	animations: [
		trigger('sidebarAnimation', [
			state(
				'collapse',
				style({
					width: '280px'
				})
			),
			state(
				'uncollapse',
				style({
					width: '60px'
				})
			),
			transition('collapse => uncollapse', [animate('300ms ease-in-out', style({ width: '60px' }))]),
			transition('uncollapse => collapse', [animate('300ms ease-in-out', style({ width: '280px' }))])
		])
	]
})
export class NavigationSidebarComponent<T> extends BaseComponent implements OnChanges, AfterViewInit {
	@Input()
	public navigationData!: Array<NavigationListDataViewModel<T>>;

	@Input()
	public navigationRoutes!: Record<string, () => string | undefined>;

	@Input()
	public navigationStates!: Array<NavigationListStatesViewModel<T>>;

	public navigationList!: Array<NavigationListViewModel<T>>;

	public isHovered = false;

	public canBeExpanded!: boolean;

	private readonly UNNPINNED_RESOLUTION = 1460;

	constructor(private sidebarStateStore: SidebarStateStore) {
		super();
	}

	public get isSidebarExpanded() {
		return this.sidebarStateStore.state;
	}

	@HostListener('mouseenter')
	public onEnter() {
		this.isHovered = true;
	}

	@HostListener('mouseleave')
	public onLeave() {
		this.isHovered = false;
	}

	@HostListener('window:resize', ['$event'])
	public onResize(event: Event) {
		this.setupExpandedState((event.target as Window)?.innerWidth);
	}

	public ngOnChanges(changes: SimpleChanges) {
		const navigationData = changes.navigationData?.currentValue as Array<NavigationListDataViewModel<T>>;
		const navigationRoutes = changes.navigationRoutes?.currentValue as Record<string, () => string | undefined>;

		if (navigationData) {
			this.navigationList = navigationData.map(x => {
				return <NavigationListViewModel<T>>{
					...x,
					isExpanded: false,
					isSelected: false
				};
			});
		}

		if (navigationRoutes && this.navigationList?.length > 0) {
			this.updateNavigationRoutes(this.navigationList, navigationRoutes);
		}
	}

	public ngAfterViewInit() {
		this.setupExpandedState(window.innerWidth);
	}

	public toggleSidebar() {
		if (this.canBeExpanded) {
			this.sidebarStateStore.setSidebarState(!this.isSidebarExpanded);
			this.setSidebarWidth(this.isSidebarExpanded);
		}
	}

	private setSidebarWidth(isPinned: boolean) {
		if (isPinned) {
			document.documentElement.style.setProperty('--sidebar-width', 'var(--default-sidebar--expanded)');
		} else {
			document.documentElement.style.setProperty('--sidebar-width', 'var(--default-sidebar--collapsed)');
		}
	}

	private updateNavigationRoutes(
		navigation: Array<NavigationListViewModel<T>>,
		navigationRoutes: Record<string, () => string | undefined>
	) {
		navigation.forEach(x => {
			if (x.items) {
				this.updateNavigationRoutes(x.items, navigationRoutes);
			} else {
				x.route = this.getNavigationRoute(x.id, navigationRoutes);
			}
		});
	}

	private getNavigationRoute(id: T, navigationRoutes: Record<string, () => string | undefined>) {
		const getRoute = navigationRoutes[id as unknown as string];
		if (getRoute) {
			return getRoute();
		} else {
			return undefined;
		}
	}

	private setupExpandedState(resolution: number) {
		if (resolution <= this.UNNPINNED_RESOLUTION) {
			this.canBeExpanded = false;
			this.sidebarStateStore.setSidebarState(false);
			this.setSidebarWidth(false);
		} else {
			this.canBeExpanded = true;
			this.setSidebarWidth(this.isSidebarExpanded);
		}
	}
}
