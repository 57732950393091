import { BaseComponent } from '../../../shared/components/base.component';
import { Component, Input, OnInit } from '@angular/core';
import { ApplicationInstanceStore } from '../../core/application.service';
import { SidebarStateStore } from '../../../routes/control-analysis/_common/store/sidebar-state.store';

@Component({
	selector: 'rq-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.scss']
})
export class FooterComponent extends BaseComponent implements OnInit {
	@Input()
	public isSidebar = false;

	public year!: number;

	public version!: string;

	public isBeta!: boolean;

	constructor(public applicationService: ApplicationInstanceStore, public sidebarStateStore: SidebarStateStore) {
		super();
	}

	public get isSidebarExpanded() {
		return this.sidebarStateStore.state;
	}

	public ngOnInit() {
		this.year = new Date().getFullYear();

		this.subscriptions.push(
			this.applicationService.currentVersion$.subscribe(value => {
				this.version = value;
			})
		);

		this.subscriptions.push(
			this.applicationService.isBeta$.subscribe(value => {
				this.isBeta = value;
			})
		);
	}
}
