<strong *ngIf="isLabelVisible"
        class="m-r-md filter-by-label">{{label | translate}}</strong>
<section class="filters">
  <ng-container *ngFor="let filterConfig of config">
    <ng-container *ngIf="filterConfig.isVisible">
      <ng-container [ngSwitch]="filterConfig.filter.type">
        <ng-container *ngSwitchCase="filterType.Search">
          <rq-filter-search #filterComponent
                            class="limit-width"
                            [attr.data-e2e-test]="filterConfig.filter.displayName | translate"
                            [(ngModel)]="filterOutput[filterConfig.name].value"
                            (ngModelChange)="triggerValueChange()"
                            [name]="filterConfig.name"
                            [isDisabled]="isDisabled"
                            [displayName]="filterConfig.filter.displayName | translate"
                            [placeholder]="filterConfig.filter.placeholder | translate">
          </rq-filter-search>
        </ng-container>
        <ng-container *ngSwitchCase="filterType.Numeric">
          <rq-filter-numeric #filterComponent
                             [attr.data-e2e-test]="filterConfig.filter.displayName | translate"
                             [(ngModel)]="filterOutput[filterConfig.name].value"
                             (ngModelChange)="triggerValueChange()"
                             [name]="filterConfig.name"
                             [isDisabled]="isDisabled"
                             [suffix]="numberFilter(filterConfig.filter).suffix"
                             [allowNegativeNumbers]="numberFilter(filterConfig.filter).allowNegativeNumbers"
                             [displayName]="filterConfig.filter.displayName | translate"
                             [placeholder]="filterConfig.filter.placeholder | translate">
          </rq-filter-numeric>
        </ng-container>
        <ng-container *ngSwitchCase="filterType.Select">
          <rq-filter-select #filterComponent
                            [attr.data-e2e-test]="filterConfig.filter.displayName | translate"
                            [(ngModel)]="filterOutput[filterConfig.name].value"
                            (ngModelChange)="triggerValueChange()"
                            [name]="filterConfig.name"
                            [isDisabled]="isDisabled"
                            [displayName]="filterConfig.filter.displayName | translate"
                            [placeholder]="filterConfig.filter.placeholder | translate"
                            [maxSelected]="selectFilter(filterConfig.filter).maxSelected"
                            [groups]="selectFilter(filterConfig.filter).groups">
          </rq-filter-select>
        </ng-container>
        <ng-container *ngSwitchCase="filterType.Range">
          <rq-filter-range #filterComponent
                           [attr.data-e2e-test]="filterConfig.filter.displayName | translate"
                           [name]="filterConfig.name"
                           [displayName]="filterConfig.filter.displayName | translate"
                           [isDisabled]="isDisabled"
                           [filter]="rangeFilter(filterConfig.filter)"
                           [(ngModel)]="filterOutput[filterConfig.name].value"
                           (ngModelChange)="triggerValueChange()">
          </rq-filter-range>
        </ng-container>
        <ng-container *ngSwitchCase="filterType.Dropdown">
          <rq-filter-dropdown #filterComponent
                              [attr.data-e2e-test]="filterConfig.filter.displayName | translate"
                              [(ngModel)]="filterOutput[filterConfig.name].value"
                              (ngModelChange)="triggerValueChange()"
                              [name]="filterConfig.name"
                              [isDisabled]="isDisabled"
                              [displayName]="filterConfig.filter.displayName | translate"
                              [placeholder]="filterConfig.filter.placeholder | translate"
                              [options]="dropdownFilter(filterConfig.filter).options"
                              [searchBoxConfig]="dropdownFilter(filterConfig.filter).searchBoxConfig">
          </rq-filter-dropdown>
        </ng-container>
        <ng-container *ngSwitchCase="filterType.Calendar">
          <rq-filter-calendar #filterComponent
                              [attr.data-e2e-test]="filterConfig.filter.displayName | translate"
                              [(ngModel)]="filterOutput[filterConfig.name].value"
                              (ngModelChange)="triggerValueChange()"
                              [name]="filterConfig.name"
                              [isDisabled]="isDisabled"
                              [displayName]="filterConfig.filter.displayName | translate"
                              [placeholder]="filterConfig.filter.placeholder | translate"
                              [format]="calendarFilter(filterConfig.filter).datePattern"
                              [options]="calendarFilter(filterConfig.filter).options">
          </rq-filter-calendar>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>
</section>
