<rq-modal #modal
          class="modal-sm">
  <rq-modal-header class="modal-header">
    <span>{{'global_info_label' | translate}}</span>
    <rq-icon href="icon-close"
             class="icon-cancel white-fill"
             (click)="closeModal()"></rq-icon>
  </rq-modal-header>
  <rq-modal-body>
    <a target="_blank"
       rel='noopener'
       href="https://threatconnect.com">
      <span class="accent-color">ThreatConnect</span>
    </a>
    <span> {{'footer_label_info' | translate: {YEAR: year, VERSION: version} }} </span>
    <span *ngIf="isBeta"
          class="badge-secondary-sm-b m-l-xs">{{ 'global_beta_label' | translate }}</span>
  </rq-modal-body>
</rq-modal>
